import styled, { css } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint, Header } from 'react-components'

import { BackIcon, MiniLogoIcon } from '../../../common/icons'

export const StickyContainer = styled.div`
    @media only screen and (max-width: ${tabletBreakpoint.min}px) {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 100;
        border-bottom: 1px solid #e0e0e0;
    }
`

export const HeaderBar = styled.section`
    position: ${({ theme }) => theme.style.position.relative};
    display: flex;
    justify-content: ${({ theme }) => theme.style.justifyContent.spaceBetween};
    top: 0;
    width: ${({ theme }) => theme.layout.fill};
    height: 3.25rem;
    z-index: 100;
    background-color: ${({ theme }) => theme.colors.white};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 79.75rem;
        height: 5rem;
        margin: 0 auto;
    }
`

export const SideBarItemWrapper = styled.div`
    display: flex;
`
export const SideBarItemIcon = styled.img`
    border-bottom: ${props => props.theme.typography.border.width.xxxThin} solid
        ${props => props.theme.colors.tfwBlack12};
    padding: 0 ${props => props.theme.spacing.small};
`

export const HeaderBarContent = styled.div`
    width: inherit;
    height: inherit;
    display: flex;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    align-items: center;
    justify-content: space-between;
    font: ${({ theme }) =>
        `${theme.typography.font.weight.normal} ${theme.typography.font.size.large} ${theme.typography.font.family.latoVerdana}`};
    padding: 0 ${props => props.theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 ${props => props.theme.spacing.large};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0 ${props => props.theme.spacing.medium};
    }
`

export const NavigationItem = styled.a`
    color: ${props => props.theme.colors.tfwBlack87};
    padding: 0.625rem ${({ theme }) => theme.spacing.xSmall};
    font: ${({ theme }) =>
        `${theme.typography.font.weight.normal} ${theme.typography.font.size.small} ${theme.typography.font.family.lato}`};
    @media (min-width: ${tabletBreakpoint.min}px) {
        display: flex;
        align-items: center;
        padding: 1.25rem 0.625rem;
        font-size: 0.82rem;
        line-height: normal;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.tfwBlack87};

        &:hover {
            text-decoration: underline;
        }
    }
    @media (min-width: ${desktopBreakpoint.min}px) {
        padding: 1.25rem 0.875rem;
        font-size: ${({ theme }) => theme.typography.font.size.medium};
    }
    @media (min-width: ${wideScreenBreakpoint.min}px) {
        padding: 1.25rem ${({ theme }) => theme.spacing.medium};
    }
`

export const NavigationButton = styled(NavigationItem)`
    cursor: pointer;
`

export const GhostButtonContent = styled.div`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 0.82rem;
        font-weight: ${({ theme }) => theme.typography.font.weight.normal};
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: ${({ theme }) => theme.typography.font.size.medium};
        font-weight: ${({ theme }) => theme.typography.font.weight.normal};
        padding: 0.3125rem 1.5625rem;
    }

    :hover {
        color: ${({ theme }) => theme.colors.white};
    }
`

export const ProfileContent = styled.div``

export const UserMenu = styled.div`
    font-size: ${({ theme }) => theme.typography.font.size.small};
    display: flex;
    flex-direction: column;
`

export const UserMenuLowerContent = styled.div`
    font-weight: ${({ theme }) => theme.typography.font.weight.normal};
`

export const DownArrow = styled.img`
    height: 0.75rem;
    width: 0.75rem;
`

export const PopUpBox = styled.div`
    display: flex;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    width: 13.125rem;
    z-index: 100;
    right: 5%;
    top: 3.5rem;
    border: ${({ theme }) => theme.typography.border.width.xxxThin} solid ${({ theme }) => theme.colors.tfwBlack12};
    border-radius: ${({ theme }) => theme.typography.border.width.small};
    box-shadow: 0 0.0625rem 0.125rem 0 ${({ theme }) => theme.colors.tfwBlack45};
    animation: fadeIn 200ms ease-out;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        top: 4.375rem;
        right: 2%;
    }
`

export const PopUpBoxWrapper = styled.div`
    display: flex;
    width: ${({ theme }) => theme.layout.fill};
    padding: 0 ${props => props.theme.spacing.small};

    &:hover {
        background-color: ${({ theme }) => theme.colors.tfwBlack12};
    }
`

export const PopUpBoxIcon = styled.img``

export const PopUpBoxItem = styled.a`
    padding: 0.625rem;
    line-height: 1.875rem;
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.tfwBlack87};

    &:hover {
        background-color: ${({ theme }) => theme.colors.tfwBlack12};
    }
`

export const LogoWrapper = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    height: ${({ theme }) => theme.layout.fill};
    color: ${({ theme }) => theme.colors.tfwBlack87};
    padding: 0.625rem 0.625rem 0.625rem ${({ theme }) => theme.spacing.xSmall};
    cursor: pointer;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0.625rem ${({ theme }) => theme.spacing.medium} 0.625rem 0;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0.625rem ${({ theme }) => theme.spacing.medium} 0.625rem ${({ theme }) => theme.spacing.xSmall};
    }
`

export const LogoImage = styled.img`
    height: ${({ theme }) => theme.layout.xSmall};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 2.063rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        height: 2.5rem;
    }
`

export const IconImage = styled.img`
    height: ${({ theme }) => theme.layout.xSmall};
    width: ${({ theme }) => theme.layout.xSmall};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 1.125rem;
        width: 1.125rem;
        margin-right: 0.3125rem;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        height: ${({ theme }) => theme.layout.xSmall};
        width: ${({ theme }) => theme.layout.xSmall};
    }
`

export const UserIcon = styled.img`
    height: ${({ theme }) => theme.layout.xSmall};
    margin-right: 0.3125rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 1.125rem;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        height: ${({ theme }) => theme.layout.xSmall};
    }
`

export const MenuImage = styled.img`
    height: ${({ theme }) => theme.layout.xxSmall};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 1.125rem;
    }
`

export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({ theme }) => theme.style.justifyContent.spaceBetween};
    height: ${({ theme }) => theme.layout.fill};
`
export const RightSubContainer = styled.div`
    display: flex;
    align-items: center;
    height: ${({ theme }) => theme.layout.fill};
`

export const LeftSubContainer = styled.div`
    display: flex;
    align-items: center;
    height: ${({ theme }) => theme.layout.fill};
`

interface CenterSubContainerProps {
    isSearchbarPresent?: boolean
}

export const CenterSubContainer = styled.div<CenterSubContainerProps>(
    ({ isSearchbarPresent }) => css`
        display: flex;
        align-items: center;
        height: ${({ theme }) => theme.layout.fill};

        @media only screen and (min-width: ${tabletBreakpoint.min}px) and (max-width: ${tabletBreakpoint.max}px) {
            ${isSearchbarPresent &&
            css`
                max-width: 70%;
            `};
        }
    `,
)

export const ButtonWrapper = styled(LogoWrapper)`
    position: relative;
    padding: 0.625rem ${({ theme }) => theme.spacing.xSmall};

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding-left: 1.25rem;

        &:hover {
            text-decoration: underline;
        }
    }
`

export const LoggedInButtonWrapper = styled.div`
    position: ${({ theme }) => theme.style.position.relative};
    display: flex;
    align-items: center;
    text-decoration: none;
    height: ${({ theme }) => theme.layout.fill};
    color: ${({ theme }) => theme.colors.tfwBlack87};
    padding: 0.625rem ${({ theme }) => theme.spacing.xSmall};
    cursor: pointer;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0.625rem 0 0.625rem 0.625rem;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding-left: ${({ theme }) => theme.spacing.small};
        padding-right: ${({ theme }) => theme.spacing.xSmall};

        &:hover {
            text-decoration: underline;
        }
    }
`

export const GhostButtonWrapper = styled.a`
    display: flex;
    align-items: center;
    padding: 0.625rem;
    font-size: ${({ theme }) => theme.typography.font.size.xSmall};
    font-weight: ${props => props.theme.typography.font.weight.normal};
    text-decoration: none;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding-left: ${({ theme }) => theme.spacing.medium};
    }
`

export const MenuIconWrapper = styled.div`
    display: flex;
    align-items: center;
    height: ${({ theme }) => theme.layout.fill};
    text-decoration: none;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    cursor: pointer;
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    padding: 0.625rem ${({ theme }) => theme.spacing.xSmall};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0.625rem 0 0.625rem ${({ theme }) => theme.spacing.xSmall};
    }
`

export const BackButton = styled.a`
    cursor: pointer;
`

export const BackIconContainer = styled(BackIcon)`
    height: 1rem;
    width: 1rem;
`

export const MiniLogo = styled(MiniLogoIcon)``
export const MiniLogoWrapper = styled(LogoWrapper)`
    padding: 0.625rem 0 !important;
`

export const LoginButtonText = styled.span`
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    display: none;

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        display: block;
    }
`

export const WishlistButtonWrapper = styled.div`
    @media only screen and (max-width: ${tabletBreakpoint.min}px) {
        padding-top: 0.2rem;
    }
`
